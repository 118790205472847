import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/individualModel';

export default {
    /**
     * @description: 支付池-转薪酬查询
     * @return {void}
    */
    'POST/pay_pool/transfer_payroll/query': (params: any, extra?: Extra) => {
        return axios(`/pay_pool/transfer_payroll/query`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 支付池-转薪酬
     * @return {void}
    */
    'POST/pay_pool/transfer_payroll': (params: any, extra?: Extra) => {
        return axios(`/pay_pool/transfer_payroll`, preParams(params, extra, 'post'))
    },

    /**
     * @description 支付池 绑定交易记录
     * @param {number} Id
     * @param {number} flwoId
     * @return {void}
    */
    'POST/pay_pool/bind': (params: any, extra?: Extra) => {
        return axios(`/pay_pool/bind`, preParams(params, extra, 'post'))
    },
    
    /**
     * @description: 支付池_交易记录明细查询
     * @param {number} Id
     * @return {void}
    */
    'POST/pay_pool/flow_detail': (params: any, extra?: Extra) => {
      return axios(`/pay_pool/flow_detail`, preParams(params, extra, 'post'))
    },
        
    /**
     * @description: 支付池_解绑交易记录(删除)
     * @param {number} flowId
     * @return {void}
    */
    'POST/pay_pool/unbind': (params: {flowId: number}, extra?: Extra) => {
      return axios(`/pay_pool/unbind`, preParams(params, extra, 'post'))
    },

    /**
     * @description: 单据审批-批量设置是否需要绑定流水
     * @return {void}
    */
    'PATCH/invoice_expense_vouchers/need_bind_flow': (params: {flowId: number}, extra?: Extra) => {
      return axios(`/invoice_expense_vouchers/need_bind_flow`, preParams(params, extra, 'patch'))
    },

    /**
     * @description: 支付池下拉框
     * @return {void}
    */
    'GET/pay_pool/drop_list': () => axios.get(`/pay_pool/drop_list`),
    
    /**
     * @description: 获取付款公司
     * @return {void}
    */
    'GET/pay_pool/conditions_drop_box': (params: {currency: string}) => axios.get(`/pay_pool/conditions_drop_box`, preParams(params, {}, 'get')),

    /**
     * @description: 支付池查询
     * @param {number} page
     * @param {number} size
     * @return {void}
    */
    'POST/pay_pool/conditions': (params: any, extra?: Extra) => {
      return axios(`/pay_pool/conditions`, preParams(params, extra, 'post'))
    },
    
    /**
     * @description: 支付池_修改紧急状态
     * @param {number} urgent
     * @param {number} id
     * @return {void}
    */
    'PATCH/pay_pool/urgent': (params: {id: number, urgent: number}, extra?: Extra) => {
      return axios(`/pay_pool/urgent`, preParams(params, extra, 'patch'))
    },
    
    /**
     * @description: 支付池_更新备注
     * @param {number} id
     * @param {string} note
     * @return {void}
    */
    'PATCH/pay_pool/update_note': (params: {id: number, note: string}, extra?: Extra) => {
      return axios(`/pay_pool/update_note`, preParams(params, extra, 'patch'))
    },
    
    /**
     * @description: 支付检测接口（去支付）
     * @param {array} ids
     * @return {void}
    */
    'POST/pay_pool/prepare_pay': (params: {ids: number[]}, extra?: Extra) => {
      return axios(`/pay_pool/prepare_pay`, preParams(params, extra, 'post'))
    },
}